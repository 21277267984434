<template>
  <div>
    <div class="modal-bg">
      <div class="modal">
        <div v-if="didWin">
          <div class="heading">You won!</div>
          <div class="details">
            Great job! You won in {{ numOfTries == 1 ? "1 try" : `${numOfTries} tries` }}.  Want to share your results? Click the share button below!
          </div>
          <div>
<button class="share-btn" @click="share">Share</button>
          </div>
          <div>
<button class="close-btn" @click="close">Close</button>
          </div>
          
        </div>
        <div v-if="!didWin">
          <div class="heading">Losers say what?</div>
          <div class="details">
            There's no polite way to say it. You lost. The word was <b>{{ word }}</b>. Want to share your results? Click the share button below!       
          </div>
          <div>
<button class="share-btn" @click="share">Share</button>
          </div>
            <div>
<button class="close-btn" @click="close">Close</button>
            </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    word: {
      type: Object,
      default: null,
    },
    attempts: {
      type: Object,
      default: null,
    },
  },
  methods: {
    close(){
        this.$emit('dismiss')
    },
    async share() {
      let day = dayjs(new Date()).format("MMDDYY");
      let numOfTries = "X";
      let winningTry = this.attempts.find((a) => a.resultPattern == "22222");
      if (winningTry) {
        numOfTries = winningTry.id;
      }
      let blockText = "";
      let completedAttempts = this.attempts.filter(
        (a) => a.resultPattern != ""
      );

      for (const attempt of completedAttempts) {
        for (const char of attempt.resultPattern) {
          let val = "⬜";
          if (char == "1") {
            val = "🟨";
          } else if (char == "2") {
            val = "🟩";
          }
          blockText += val;
        }
        blockText += "\n";
      }
      let heading = `MORDLE!  ${day}  ${numOfTries}/6`;
      navigator.share({
        text: `${heading}\n\n${blockText}`,
      });
      console.log("Data was shared successfully");
    },
  },
  computed: {
    didWin() {
      let winner = this.attempts.find((a) => a.resultPattern == "22222");
      if (winner) {
        return true;
      }
      return false;
    },
    numOfTries() {
      return this.attempts.find((a) => a.resultPattern == "22222").id;
    },
  },
};
</script>

<style  scoped>
.modal-bg {
  position: fixed;
  background: rgba(0, 0, 0, 0.224);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
}
.modal {
  width: 80vw;
  /* height: 60vh; */
  max-width: 400px;
  max-height: 800px;
  background-color: white;
  border-radius: 12px;
  z-index: 1;
  margin: auto;
  padding:40px;
  box-sizing:border-box;
  box-shadow: 0.6px 1.1px 1.1px hsl(0deg 0% 0% / 0.48);

}
.share-btn {
  background-color: var(--green);
  color: white;
  padding: 14px;
  border-radius: 30px;
  font-size: 20px;
  min-width: 100%;
  border: none;
  border: 1px solid rgb(38,126,234);
  background-color: rgb(38, 126, 234);
}

.close-btn {
  background-color: var(--green);
  color: black;
  padding: 14px;
  border-radius: 30px;
  font-size: 20px;
  min-width: 100%;
  border: none;
  border: 1px solid rgb(38,126,234);
  color: rgb(38,126,234);
  margin-top:20px;
  background-color: white;
}

.heading {
  font-size: 30px;
  margin-top: 0px;
  margin-bottom: 40px;
  font-weight: 800;
}
.details {
  font-size: 18px;
  margin-bottom: 30px;
}
</style>