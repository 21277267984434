<template>
  <div style="display: flex" class="letter-sequence-parent">
    <div style="flex:1;"></div>
    <LetterBox :letterIndex="0" :attempt="attempt"></LetterBox>
    <LetterBox :letterIndex="1" :attempt="attempt"></LetterBox>
    <LetterBox :letterIndex="2" :attempt="attempt"></LetterBox>
    <LetterBox :letterIndex="3" :attempt="attempt"></LetterBox>
    <LetterBox :letterIndex="4" :attempt="attempt"></LetterBox>   
    <div style="flex:1;"></div>
  </div>
</template>

<script>
import LetterBox from "@/components/LetterBox.vue";
export default {
  components: {
    LetterBox,
  },
  props: {
    attempt: {
      type: Object,
      default: null,
    },
    word: {
      type: String,
      default: null,
    },
  },
  computed: {
    letter1() {
      if (this.attempt.letters.length < 1) {
        return "";
      }

      return this.attempt.letters.substr(0, 1);
    },
    letter2() {
      if (this.attempt.letters.length < 2) {
        return "";
      }
      return this.attempt.letters.substr(1, 1);
    },
    letter3() {
      if (this.attempt.letters.length < 3) {
        return "";
      }

      return this.attempt.letters.substr(2, 1);
    },
    letter4() {
      if (this.attempt.letters.length < 4) {
        return "";
      }

      return this.attempt.letters.substr(3, 1);
    },
    letter5() {
      if (this.attempt.letters.length < 5) {
        return "";
      }

      return this.attempt.letters.substr(4, 1);
    },
  },
  data() {
    return {
      letters: [
        { l1: { value: "", color: "" } },
        { l2: { value: "", color: "" } },
        { l3: { value: "", color: "" } },
        { l4: { value: "", color: "" } },
        { l5: { value: "", color: "" } },
      ],
    };
  },
  methods: {
    getSubmittedClassOld(letter, index) {
      let word = this.word.toUpperCase();
      if (!this.attempt.submitted) return ``;

      if (word.substr(index, 1) == letter.toUpperCase()) {
        return `green`;
      }

      if (word.includes(letter.toUpperCase())) {
        return `yellow`;
      }
      return `gray`;
    },
    getSubmittedClass(index) {
      if (!this.attempt.submitted) return ``;

      if (this.attempt.resultPattern[index] == 2) {
        return `green`;
      }

      if (this.attempt.resultPattern[index] == 1) {
        return `yellow`;
      }
      return `gray`;
    },
    itemSubmitted() {
      console.log("ITEM SUBMITTED!");

      //   for (var i = 0; i < attempt.letters.length; i++) {
      //     let letter = attempt.letters[i].toUpperCase();
      //     if (word.substr(i, 1) == letter) {
      //       this.keys.green.push(letter);
      //     } else if (word.includes(letter)) {
      //       this.keys.yellow.push(letter);
      //     } else {
      //       this.keys.gray.push(letter);
      //     }
      //   }

      this.$emit("attemptSubmitted", this.attempt);
    },
  },
};
</script>

<style scoped>
.letter-sequence-parent {
  font-weight: 600;
  font-size: 35px;
}
.letter {
  margin: auto;

  transform: translateY(20px);
  transition: 0.1s ease-out;
}

.filled {
  transform: translateY(0px);
}

.letter-wrapper {
  /* border-radius: 6px; */
  /* padding: 12px; */
  border: none;
  background-color: whitesmoke;
  width: 15%;
  text-align: center;
  /* font-size: 3em; */
  text-transform: uppercase;
  margin: 5px;
  height: 50px;
  display: flex;
  align-items: center;
}

.letter:focus {
  outline: none;
}
.letter-wrapper.green {
  background-color: var(--green);
  color: black;
}
.letter-wrapper.yellow {
  background-color: var(--yellow);
  color: black;
}
.letter-wrapper.gray {
  background-color: var(--gray);
  color: rgb(0, 0, 0);
}

.flip-box {
  background-color: transparent;
  width: 300px;
  height: 200px;
  border: 1px solid #f1f1f1;
  perspective: 1000px;

  /* padding: 12px; */
  border: none;
  background-color: whitesmoke;
  width: 15%;
  text-align: center;
  /* font-size: 3em; */
  text-transform: uppercase;
  margin: 5px;
  height: 50px;
  display: flex;
  align-items: center;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box.submitted .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-front {
  background-color: #bbb;
  color: black;
}

.flip-box-back.green {
  background-color: var(--green);
  color: black;
  transform: rotateY(180deg);
}

.flip-box-back.gray {
  background-color: var(--gray);
  color: black;
  transform: rotateY(180deg);
}

.flip-box-back.yellow {
  background-color: var(--yellow);
  color: black;
  transform: rotateY(180deg);
}
</style>