<template>
  <div>
    <div class="modal-bg">
      <div class="modal">
        <div class="details">That's not a word!</div>
        <div>
          <button class="close-btn" @click="close">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    word: {
      type: Object,
      default: null,
    },
    attempts: {
      type: Object,
      default: null,
    },
  },
  methods: {
    close() {
      this.$emit("dismiss");
    },
    
  },  
};
</script>

<style  scoped>
.modal-bg {
  position: fixed;
  background: rgba(0, 0, 0, 0.224);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
}
.modal {
  width: 80vw;
  /* height: 60vh; */
  max-width: 400px;
  max-height: 800px;
  background-color: white;
  border-radius: 12px;
  z-index: 1;
  margin: auto;
  padding: 40px;
  box-sizing: border-box;
}

.close-btn {
  
  color: black;
  padding: 14px;
  border-radius: 30px;
  font-size: 20px;
  min-width: 50%;
  border: none;
  background-color: white;
}

.heading {
  font-size: 36px;
  margin-top: 30px;
  margin-bottom: 40px;
  font-weight: 800;
}
.details {
  font-size: 18px;
  margin-bottom: 20px;
}
</style>