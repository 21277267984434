<template>
<TypeScriptComponent v-if="false"></TypeScriptComponent>

  <InvalidWord v-if="invalidWord" @dismiss="invalidWord = false"></InvalidWord>
  <GameOver
    v-if="showGameOverDialog"
    :attempts="attempts"
    :word="word"
    @dismiss="showGameOverDialog = false"
  ></GameOver>
  <div class="header" style="display:flex;align-items:center;">
    <div style="flex:1;"></div>
    <div>
 MORDLE!
    </div>
    <img src="@/assets/mordle-logo.png" alt="" style="margin-left:20px;width:48px;">
    <div style="flex:1;"></div>
    </div>

    <div v-if="gameOver && !didWin" style="font-size:18px;margin-bottom:20px;">
      <div>
The word was <b> {{word}}</b>
      </div>
      <div>
Better luck tomorrow!
      </div>
  
</div>

    <div v-if="gameOver && didWin" style="font-size:18px;margin-bottom:20px;">
      <div>
Good job! You won!
      </div>
      <div>
Check back tomorrow for a new word.
      </div>
  
</div>

  <LetterSequence
    v-for="attempt in attempts"
    :attempt="attempt"
    :key="attempt.id"
    @attemptSubmitted="attemptSubmitted"
    :word="word"
    :ref="`attempt${attempt.id}`"
  >
  </LetterSequence>
  <div style="height: 300px"></div>
  <div class="virtual-keyboard-container">
    <VirtualKeyboard
      :keys="keys"
      style="margin: auto"
      @keyclick="keyboardClick"
    ></VirtualKeyboard>
  </div>
</template>

<script>
import TypeScriptComponent from '@/components/TypeScriptComponent'
import LetterSequence from "@/components/LetterSequence";
import VirtualKeyboard from "@/components/VirtualKeyboard.vue";
import GameOver from "@/components/GameOver.vue";
import axios from "axios";
import dayjs from "dayjs";
import InvalidWord from "@/components/InvalidWord";
export default {
  name: "App",
  components: {
    LetterSequence,
    VirtualKeyboard,
    GameOver,
    InvalidWord,
    TypeScriptComponent
  },
  computed: {
    didWin() {
      let winner = this.attempts.find((a) => a.resultPattern == "22222");
      if (winner) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      success: false,
      date: "",
      invalidWord: false,
      attempts: [
        { id: 1, letters: "", submitted: false, resultPattern: "" },
        { id: 2, letters: "", submitted: false, resultPattern: "" },
        { id: 3, letters: "", submitted: false, resultPattern: "" },
        { id: 4, letters: "", submitted: false, resultPattern: "" },
        { id: 5, letters: "", submitted: false, resultPattern: "" },
        { id: 6, letters: "", submitted: false, resultPattern: "" },
      ],
      keys: {
        green: [],
        gray: [],
        yellow: [],
      },
      word: "SHELL",
      words: [
        { date: "02222023", word: "VINYL" },
        { date: "02232023", word: "FLASH" },
        { date: "02242023", word: "SLIDE" },
        { date: "02252023", word: "TWIST" },        
        { date: "02262023", word: "GUESS" },
        { date: "02272023", word: "PUPPY" },
        { date: "02282023", word: "HOVER" },
        { date: "02292023", word: "IMPLY" },
        { date: "03012023", word: "INTRO" },
        { date: "03022023", word: "JUICY" },
        { date: "03032023", word: "YUMMY" },
        { date: "03042023", word: "QUERY" },
        { date: "03052023", word: "BORED" },
        { date: "03062023", word: "AMAZE" },
        { date: "03072023", word: "JEWEL" },
        { date: "03082023", word: "LOBBY" },
        { date: "03092023", word: "MERGE" },
        { date: "03102023", word: "ORGAN" },
        { date: "03112023", word: "PRIME" },
        { date: "03122023", word: "USAGE" },
        { date: "03132023", word: "WEIRD" },
        { date: "03142023", word: "GHOST" },
        { date: "03152023", word: "FRIES" },
        { date: "03162023", word: "OLLIE" },
        { date: "03172023", word: "GRAPE" },
        { date: "03182023", word: "MOWER" },
        { date: "03192023", word: "EXACT" },
        { date: "03202023", word: "FONTS" },
        { date: "03212023", word: "MONTH" },
        { date: "03222023", word: "GNOME" },
      ],
      gameOver: false,
      showGameOverDialog:false
    };
  },
  created() {
    this.date = dayjs(new Date()).format("MMDDYYYY");
    if(this.words.find(w=>w.date == this.date)){
      this.word = this.words.find(w=>w.date == this.date).word;
    }
    if (localStorage.getItem("ATTEMPTS")) {
      let cachedAttempts = JSON.parse(localStorage.getItem("ATTEMPTS"));
      if (cachedAttempts.date == this.date) {
        this.attempts = cachedAttempts.attempts;
      }
    }
    if(this.attempts.filter(a=>a.resultPattern == "").length == 0 || this.attempts.filter(a=>a.resultPattern == "22222").length != 0){
      this.gameOver = true;
      this.showGameOverDialog = true;
    }
  },
  mounted() {
    document
      .getElementsByTagName("body")[0]
      .addEventListener("keyup", (event) => {
        let key = event.key.toUpperCase();
        this.keyboardClick(key);
      });
  },
  methods: {
    async isValidWord(word) {
      if(this.word.toUpperCase() == word.toUpperCase()){
        return true;
      }

      try {
        await axios.get(
          `https://api.dictionaryapi.dev/api/v2/entries/en/${word}`
        );
        return true;
      } catch {
        return false;
      }
    },
    keyboardClick(key) {
      if(this.attempts.find(a=>a.resultPattern == '22222')){ // game is over
        return;
      }

      let validEntries = this.getValidKeyboardEntries();

      let activeAttempt = this.attempts.find((a) => a.submitted == false);
      if (validEntries.includes(key) && activeAttempt.letters.length < 5) {
        activeAttempt.letters = activeAttempt.letters + key;
        console.log(this.letters);
      } else if (key == "BACKSPACE" && activeAttempt.letters.length > 0) {
        activeAttempt.letters = activeAttempt.letters.substring(
          0,
          activeAttempt.letters.length - 1
        );
      } else if (key == "ENTER" && activeAttempt.letters.length == 5) {
        //activeAttempt.enterPressed = true;
        //let attempt = this.$refs[`attempt${activeAttempt.id}`];
        //console.log(attempt);
        //attempt[0].itemSubmitted();

        let attempt = this.attempts.filter((a) => a.resultPattern == "");
        this.attemptSubmitted(attempt[0]);
      }
    },
    getValidKeyboardEntries() {
      return [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
    },
    async attemptSubmitted(attempt) {
      let isValidWord = await this.isValidWord(attempt.letters);
      if (!isValidWord) {
        console.log("INVALID WORD!!");
        this.invalidWord = true;
        return;
      }
      let keys = this.keys;
      let word = this.word.toUpperCase();
      //let attempt = this.attempts.find((a) => a.id == item.id);
      let resultPattern = "";
      let reviewedLetters = [];

      // Add all the correct letters that are in the right place into the reviewedLetters array before we do anything else.
      for (let i = 0; i < attempt.letters.length; i++) {
        let letter = attempt.letters[i].toUpperCase();
        if (word.substr(i, 1) == letter) {          
          reviewedLetters.push(letter)
        }        
      }

      // Loop through each letter
      for (let i = 0; i < attempt.letters.length; i++) {
        let letter = attempt.letters[i].toUpperCase();
        if (word.substr(i, 1) == letter) {
          keys.green.push(letter);
          resultPattern += "2";
        } else if (word.includes(letter)) {
          let numOfOccurrences = [...word].filter(w=>w == letter).length;
          console.log({letter:letter,numberOfOccurrences:numOfOccurrences})
          
          let numAlreadyFound = reviewedLetters.filter(
            (l) => l == letter
          ).length;
          if (numOfOccurrences > numAlreadyFound) {
            keys.yellow.push(letter);
            resultPattern += "1";
          } else {
            keys.gray.push(letter);
            resultPattern += "0";
          }
          reviewedLetters.push(letter);
        } else {
          keys.gray.push(letter);
          resultPattern += "0";
          reviewedLetters.push(letter);
        }        
      }

      attempt.resultPattern = resultPattern;
      attempt.submitted = true;
      if (
        attempt.resultPattern == "22222" ||
        this.attempts.filter((a) => a.resultPattern != "").length == 6
      ) {
        setTimeout(()=>{
          this.gameOver = true;
          this.showGameOverDialog = true;
        },1250)
        
      }
      console.log(resultPattern);
      localStorage.setItem(
        "ATTEMPTS",
        JSON.stringify({ date: this.date, attempts: this.attempts })
      );
    },
  },
};
</script>

<style>
:root {
  --gray: rgb(156, 156, 156);
  --yellow: rgb(255, 213, 0);
  --green: rgb(29, 172, 63);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 400px;
  /* background-color:red; */
  margin: auto;
  /* margin-top: 60px; */
}

.header {
  font-size: 28px;
  font-weight: 800;
  margin: 30px;
}
.virtual-keyboard-container {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: 200px;
  background-color: white;
  display: flex;
  align-items: center;
  border-top: 4px solid rgb(240, 240, 240);
}
</style>
