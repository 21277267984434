<template>
  <div class="flip-box" :class="attempt.submitted ? 'submitted' : ''">
    <div class="flip-box-inner" :class="`l${letterIndex}`">
      <div class="flip-box-front">
        <div class="letter" :class="letter.length > 0 ? 'filled' : ''">
          {{ letter }}
        </div>
      </div>
      <div class="flip-box-back" :class="getSubmittedClass()">
        <div class="letter" :class="letter.length > 0 ? 'filled' : ''">
          {{ letter }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    attempt: {
      type: Object,
      default: null,
    },
    letterIndex: {
      type: Number,
      default: null,
    },
  },
  computed: {
    letter() {
      if (this.attempt.letters.length < this.letterIndex - 1) {
        return "";
      }
      return this.attempt.letters.substr(this.letterIndex, 1);
    },
  },
  methods: {
    getSubmittedClass() {
      if (!this.attempt.submitted) return `x`;

      if (this.attempt.resultPattern[this.letterIndex] == "2") {
        return `green`;
      }

      if (this.attempt.resultPattern[this.letterIndex] == "1") {
        return `yellow`;
      }
      return `gray`;
    },
  },
};
</script>

<style scoped>
.letter-sequence-parent {
  font-weight: 600;
  font-size: 35px;
}
.letter {
  margin: auto;

  transform: translateY(20px);
  transition: 0.1s ease-out;
}

.filled {
  transform: translateY(0px);
}

.letter-wrapper {
  /* border-radius: 6px; */
  /* padding: 12px; */
  border: none;
  background-color: whitesmoke;
  width: 15%;
  text-align: center;
  /* font-size: 3em; */
  text-transform: uppercase;
  margin: 5px;
  height: 50px;
  display: flex;
  align-items: center;
}

.letter:focus {
  outline: none;
}
.letter-wrapper.green {
  background-color: var(--green);
  color: black;
}
.letter-wrapper.yellow {
  background-color: var(--yellow);
  color: black;
}
.letter-wrapper.gray {
  background-color: var(--gray);
  color: rgb(0, 0, 0);
}

.flip-box {
  background-color: transparent;
  width: 300px;
  height: 200px;
  border: 1px solid #f1f1f1;
  perspective: 1000px;

  /* padding: 12px; */
  border: none;
  background-color: whitesmoke;
  width: 15%;
  text-align: center;
  /* font-size: 3em; */
  text-transform: uppercase;
  margin: 5px;
  height: 50px;
  display: flex;
  align-items: center;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box-inner.l0 {
    transition-delay: 0ms;
}
.flip-box-inner.l1 {
    transition-delay: 200ms;
}
.flip-box-inner.l2 {
    transition-delay: 400ms;
}
.flip-box-inner.l3 {
    transition-delay: 600ms;
}
.flip-box-inner.l4 {
    transition-delay: 800ms;
}

.flip-box.submitted .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-front {
  background-color: #bbb;
    background-color: whitesmoke;

  color: black;
}

.flip-box-back.green {
  background-color: var(--green);
  color: black;
  transform: rotateY(180deg);
}

.flip-box-back.gray {
  background-color: var(--gray);
  color: black;
  transform: rotateY(180deg);
}

.flip-box-back.yellow {
  background-color: var(--yellow);
  color: black;
  transform: rotateY(180deg);
}
</style>