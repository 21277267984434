<template>
  <div>
    <div class="key-row">
      <div style="flex: 1"></div>
      <div
        v-for="key in keyRow1"
        :key="key"
        class="key"
        @click="$emit('keyclick', key.toUpperCase())"
        :class="getClassKey(key)"
      >
      <div class="key-content">
{{ key.toUpperCase() }}
      </div>
        
      </div>
      <div style="flex: 1;"></div>
    </div>

    <div class="key-row">
      <div style="flex: 1"></div>

      <div
        v-for="key in keyRow2"
        :key="key"
        class="key"
        @click="$emit('keyclick', key.toUpperCase())"
        :class="getClassKey(key)"
        
      >
      <div style="margin:auto;text-align:center;">
{{ key.toUpperCase() }}
      </div>
        
      </div>
      <div style="flex: 1"></div>
    </div>

    <div class="key-row">
      <div style="flex: 1"></div>
      <div
        class="key special"
        @click="$emit('keyclick', 'ENTER')"
        
      >
        <div class="key-content">ENTER</div>
      </div>
      <div
        v-for="key in keyRow3"
        :key="key"
        class="key"
        @click="$emit('keyclick', key.toUpperCase())"
        :class="getClassKey(key)"
      >
           <div class="key-content">
{{ key.toUpperCase() }}
      </div>
      </div>
<div
        class="key special"
        @click="$emit('keyclick', 'BACKSPACE')"

        
      >
        <div class="key-content">BACK</div>
      </div>
      
      <div style="flex: 1"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    keys: {
      type: Object,
      default: null,
    },
  },
  methods: {
    getClassKey(key) {
      if (this.keys.green.includes(key.toUpperCase())) {
        return "green";
      }

      if (this.keys.yellow.includes(key.toUpperCase())) {
        console.log("yellow key..");
        return "yellow";
      }

      if (this.keys.gray.includes(key.toUpperCase())) {
        return "gray";
      }
      return "";
    },
  },
  data() {
    return {
      keyRow1: ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
      keyRow2: ["a", "s", "d", "f", "g", "h", "j", "k", "l"],
      keyRow3: ["z", "x", "c", "v", "b", "n", "m"],
    };
  },
};
</script>

<style scoped>
.key-row {
  display: flex;
  margin: auto;
}
.key {
  margin: 4px;
  background-color: whitesmoke;
  /* padding: 10px; */
  border-radius: 4px;
  font-size: 18px;
  text-align:center;
  display:flex;
  align-items: center;
  /* box-sizing:border-box; */
}
.key.special{
    font-size: 12px; display: flex; align-items: center;min-width:52px;
    
}

.key.green {
  background-color: var(--green);
}
.key.yellow {
  background-color: var(--yellow);
  color: black;
}
.key.gray {
  background-color: var(--gray);
  color: black;
}



.key{
    min-width:29px;
    max-width:29px;
    
    min-height:44px;max-height:44px;
}
.key-content{
    margin:auto;    
    
    
    
}
</style>